@import '../../Variables.scss';

.SkillsList{
    margin-bottom: 3rem;

    @media only screen and (max-width: 750px) {
        margin-top: 1.5rem;
    }

    &__header{

        @include sectionHeaderProps;

        @media only screen and (max-width: 500px) {
            padding: .5rem .5rem;
        }

        &__text{
            font-size: 2rem;
            margin: 0;
            color: $text-color;

            @media only screen and (max-width: 700px) {
                font-size: 1.5rem;
            }
        }

        &__downPointer{
            @include downPointerProps;
        }

    }

    &__list{
        display: flex;
        flex-wrap: wrap;
        padding: 1rem 1rem;

        @include sectionContentProps;

    }
}
