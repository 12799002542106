@import '../../Variables.scss';

.Navbar{
    top: 0;
    z-index: 3;
    position: -webkit-sticky; /* Safari */
    position: sticky;

    &__top{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 2vh 0;
        background-color: $base-color;
        box-shadow: 0 .5rem 3rem rgba(0,0,0,.25);
        

        @media only screen and (max-width: 700px) {
            align-items: center;
        }

        &__name{
            flex-basis: 35vw;

            &__text{
                color: $text-color;
                display: inline;
                font-size: 2.5rem;
                font-weight: bold;
                margin-left: 1rem;

                @media only screen and (max-width: 1000px) {
                    font-size: 1.5rem;
                }

                @media only screen and (max-width: 700px) {
                    margin-left: .5rem;
                }

                @media only screen and (max-width: 400px) {
                    font-size: 1.3rem;
                }
            }

            @media only screen and (max-width: 700px) {
                flex-basis: 70vw;
            }
        }

        &__links{
            display: flex;
            justify-content: space-around;
            flex-basis: 65vw;

            &__linkDiv{

                @media only screen and (max-width: 700px) {
                    display: none;
                }

                &__link{
                    color: $text-color;
                    font-size: 1.5rem;
                    transition: all $animation_duration; 
                    box-shadow: none;
                    padding: .3rem .5rem;
                    border-radius: 10px;

                    @media only screen and (max-width: 1000px) {
                        font-size: 1rem;
                        padding: .15rem .3rem;
                        border-radius: 5px;
                    }
                }
            }

            &__mobile{
                display: none;
        
                @media only screen and (max-width: 700px) {
                    display: inline;
                }
        
                &__icon{
                    font-size: 2rem;
                    margin-right: 1rem;
                }
        
            }

            @media only screen and (max-width: 700px) {
                flex-basis: 0;
                display: inline;
            }
        }
    }

    &__mobile{
        display: none;
    
        @media only screen and (max-width: 700px) {
            display: inline;
        }

        &__menu{
            box-shadow: 1rem 1rem 3rem rgba(0,0,0,.25);
            height: 100%;
            background-color: $base-color;
            position: fixed;
            top: 0;
            left: 0;
            width: 80%;
            z-index: 50;
    
            &__link{
                color: $text-color;
                font-size: 1.5rem;
                transition: all $animation_duration; 
                box-shadow: none;
                padding: 1.5rem 1rem;
                margin: .1rem 0rem;
            }
        }
    
        &__menuMask{
            background-color: rgba(255, 255, 255, .9);
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 49;
        }
    }

}

a{
    text-decoration: none;
}


