@import '../../Variables.scss';

.MeasuredSkill{
    margin: 0rem 1rem 1rem 0rem;

    @media only screen and (max-width: 800px) {
        margin: 0rem .5rem .5rem 0rem;
    }

    @media only screen and (max-width: 500px) {
        margin: 0rem .3rem .3rem 0rem;
    }

    &__name{
        font-size: 1.5rem;
        border-radius: 10px;
        width: auto;
        padding: .2rem .5rem;
        background-color: $text-color;
        color: $text-color;

        @media only screen and (max-width: 800px) {
            font-size: 1.2rem;
            border-radius: 5px;
            padding: .15rem .3rem;
        }

        @media only screen and (max-width: 500px) {
            border-radius: 5px;
            padding: .1rem .25rem;
        }
    }
}
