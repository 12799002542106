@import "../../Variables.scss";

.Resume {
  @include pageSettings(60%);

  &__Container {
    &__iframe {
      width: 100%;
      height: 900px;

      @media only screen and (max-width: 1200px) {
        height: 700px;
      }

      @media only screen and (max-width: 1000px) {
        height: 1000px;
      }

      @media only screen and (max-width: 800px) {
        height: 800px;
      }

      @media only screen and (max-width: 650px) {
        height: 650px;
      }

      @media only screen and (max-width: 500px) {
        height: 500px;
      }

      @media only screen and (max-width: 400px) {
        height: 400px;
      }

      @media only screen and (max-width: 300px) {
        height: 300px;
      }
    }

    &__driveLink {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
