@import "../../Variables.scss";

.Home {
  overflow-x: hidden;

  &__intro {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1100px) {
      flex-direction: column;
    }

    &__img {
      border-radius: 50%;
      margin: 2rem;
      width: 30rem;
      height: 30rem;

      @media only screen and (max-width: 1100px) {
        height: 23rem;
        width: 23rem;
        margin: 2rem auto 2rem auto;
      }

      @media only screen and (max-width: 500px) {
        height: 15rem;
        width: 15rem;
        margin: 2rem auto 1rem auto;
      }
    }

    &__about {
      margin: 1rem 3rem 3rem 3rem;

      @media only screen and (max-width: 500px) {
        margin: 1.5rem;
      }

      &__description {
        &__text {
          font-size: 1.5rem;
          color: $text-color;
          background-color: $base-color;
          padding: 1rem;
          border-radius: 10px;

          @media only screen and (max-width: 800px) {
            font-size: 1.2rem;
          }

          @media only screen and (max-width: 500px) {
            font-size: 1rem;
          }
        }
      }
    }
  }

  &__links {
    display: flex;
    justify-content: center;
    margin-top: 5vh;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__skills {
    margin: auto;
    width: 50%;
    margin-top: 15vh;
    margin-bottom: 15vh;

    @media only screen and (max-width: 700px) {
      font-size: 1.5rem;
    }

    @media only screen and (max-width: 1000px) {
      width: 80%;
    }

    @media only screen and (max-width: 750px) {
      margin-top: 10vh;
      margin-bottom: 10vh;
    }

    &__subheader {
      color: $base-color;
      margin-bottom: 15px;

      @media only screen and (max-width: 750px) {
        font-size: 13px;
      }
    }

    &__examples {
      display: flex;
      margin: 30px 0px;
      justify-content: space-between;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
