@import "./Variables.scss";

.App {
  background-image: linear-gradient(
    to right,
    $background-left,
    $background-right
  );

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
}
