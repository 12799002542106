@import '../../Variables.scss';

.Subheader{
    margin-bottom: .8rem;

    @media only screen and (max-width: 500px) {
        margin-bottom: .5rem;
    }

    &__text{
        font-size: 1.5rem;
        text-align: center;
        color: $text-color;

        @media only screen and (max-width: 700px) {
            font-size: 1.2rem;
        }
    }
}
