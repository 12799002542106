@import '../../Variables.scss';

.Footer{
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;

    @media only screen and (max-width: 350px) {
      margin: 0rem 1rem 2vh 1rem;
    }

    &__creator{
      color: $text-color2;
      margin-right: 2rem;

      @media only screen and (max-width: 700px) {
        margin-right: .5rem;
        font-size: .7rem;
      }
    }

    &__sources{
      color: $text-color2;
      text-decoration: underline;

      @media only screen and (max-width: 700px) {
        font-size: .7rem;
      }
    }
  }
