@import '../../Variables.scss';

.ProjectsSection{
    margin-bottom: 3rem;

    &__header{

        @include sectionHeaderProps;

        @media only screen and (max-width: 500px) {
            padding: .5rem .5rem;
        }

        &__left{
            display: flex;
            align-items: center;

            &__desc{
                &__name{
                    font-size: 1.5rem;
                    @media only screen and (max-width: 750px) {
                        font-size: 1.2rem;
                    }
                }

                &__duration{

                    display: flex;
                    align-items: center;

                    &__icon{
                        width: 1rem;
                        height: 1rem;
                        margin-right: .5rem;
    
                        @media only screen and (max-width: 750px) {
                            width: .7rem;
                            height: .7rem;
                        }
                    }
                }

                &__name, &__event{
                    font-size: 1.5rem;
    
                    @media only screen and (max-width: 750px) {
                        font-size: 1.2rem;
                    }
                }

                &__name, &__duration{
                    color: $text-color;
                }

                &__name{
                    font-weight: bold;
                }
            }
        }

        &__downPointer{
            @include downPointerProps;
        }

    }

    &__content{
        @include sectionContentProps;

        &__description{
            padding: 1rem;

            @media only screen and (max-width: 500px) {
                padding: .5rem;
            }

            &__paragraph{
                font-size: 1rem;
                color: $text-color;

                @media only screen and (max-width: 700px) {
                    font-size: .8rem;
                }
            }
        }

        &__skills{
            padding: 1rem 1rem 0rem 1rem;

            @media only screen and (max-width: 500px) {
                padding: .2rem .5rem .5rem .5rem;
            }

            &__list{
                display: flex;
                flex-wrap: wrap;
            }
        }

        &__links{
            padding: 1rem;

            @media only screen and (max-width: 500px) {
                padding: .5rem;
            }

            &__list{
                display: flex;
                flex-wrap: wrap;
            }
        }
    }   
}
