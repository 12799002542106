@import "../../Variables.scss";

.ExperienceSection {
  margin-bottom: 3rem;

  &__header {
    @include sectionHeaderProps;

    @media only screen and (max-width: 500px) {
      padding: 0.5rem 0.5rem;
    }

    &__downPointer {
      @include downPointerProps;
    }

    &__position {
      &__org,
      &__title {
        font-size: 1.5rem;

        @media only screen and (max-width: 750px) {
          font-size: 1.2rem;
        }
      }

      &__duration,
      &__loc__name {
        font-size: 1rem;
      }

      &__title,
      &__duration,
      &__loc__name {
        color: $text-color;
      }

      &__title {
        font-weight: bold;
      }

      &__org {
        color: $text-color;
      }

      &__loc {
        display: flex;
        align-items: center;

        &__icon {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;

          @media only screen and (max-width: 750px) {
            width: 0.7rem;
            height: 0.7rem;
          }
        }
      }

      &__duration {
        display: flex;
        align-items: center;

        &__icon {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;

          @media only screen and (max-width: 750px) {
            width: 0.7rem;
            height: 0.7rem;
          }
        }
      }
    }
  }

  &__content {
    @include sectionContentProps;

    &__description {
      padding: 1rem;

      &__pointsList {
        list-style-type: none;
        padding-left: 0;

        &__point {
          font-size: 1rem;
          color: $text-color;

          @media only screen and (max-width: 700px) {
            font-size: 0.8rem;
          }
        }
      }

      &__pointsList li::before {
        content: "-";
        margin-right: 8px;
      }

      @media only screen and (max-width: 500px) {
        padding: 0.5rem;
      }
    }

    &__skills {
      padding: 1rem;

      @media only screen and (max-width: 500px) {
        padding: 0.5rem;
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
