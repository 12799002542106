@import "../../Variables.scss";

.Contact {
  @include pageSettings(50%);

  margin-bottom: 15rem;
  text-align: center;

  &__text {
    color: white;
    font-size: 25px;

    @media only screen and (max-width: 500px) {
      font-size: 15px;
    }
  }
}
